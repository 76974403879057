import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const SectionContainer = styled.section`
  ${tw`
    container text-center md:pt-0 pt-16
  `}
`;

export function Section2() {
  return (
    <SectionContainer>
      <h2 className="has-ornament">
        Oprogramowanie Symfonia{" "}
        <span className="text-green">odpowiada na&nbsp;potrzeby</span> firm
        z&nbsp;Twojej branży
      </h2>
      <p className="mb-6">
        Zarządzanie towarami, operacjami magazynowymi, zamówieniami,
        transakcjami i&nbsp;płatnościami w&nbsp;wielu kanałach sprzedaży wymaga
        elastyczności i&nbsp;synchronizacji z&nbsp;firmami partnerskimi,
        odbiorcami i&nbsp;dostawcami.
      </p>
      <p className="mb-6">
        Dzięki&nbsp;wdrożeniu nowoczesnego oprogramowania Symfonia ERP zyskasz
        pełną kontrolę nad&nbsp;procesami, dokumentami i&nbsp;pracownikami.
        Przyspieszysz i&nbsp;rozwiniesz procesy sprzedaży w&nbsp;Internecie,
        w&nbsp;terenie i&nbsp;w&nbsp;biurze, uporządkujesz logistykę, rozliczysz
        kontrahentów i&nbsp;pracowników, a&nbsp;także stworzysz dowolne układy
        raportów.
      </p>
      <p>
        Złożone procesy biznesowe bezpiecznie i&nbsp;wygodnie obsłużysz
        z&nbsp;poziomu komputera, tabletu bądź&nbsp;smartfona.
      </p>
    </SectionContainer>
  );
}
