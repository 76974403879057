import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

// Images
import Logo1 from "../../assets/images/baselinker.png";
import Logo2 from "../../assets/images/sote-small.png";
import Logo3 from "../../assets/images/shoper.png";
import Logo4 from "../../assets/images/ebay.png";
import Logo5 from "../../assets/images/shoplo.png";
import Logo6 from "../../assets/images/woocommerce.png";
import Logo7 from "../../assets/images/icosell.png";
import Logo8 from "../../assets/images/opencart.png";
import Logo9 from "../../assets/images/magneto.png";
import Logo10 from "../../assets/images/shopgold.png";
import Logo11 from "../../assets/images/PrestaShop_Logo.png";
import Logo12 from "../../assets/images/redcart.png";

const SectionContainer = styled.section`
  ${tw`
    container pt-16 md:text-center
  `}

  h2 {
    ${tw`
      mb-8
    `}
  }
`;

const Grid = styled.div`
  ${tw`
    grid md:grid-cols-6 grid-cols-2 gap-x-4 gap-y-16
  `}
`;

const GridCol = styled.div`
  ${tw`
    flex items-center justify-center
  `}
`;

export function Section7() {
  return (
    <SectionContainer>
      <h2 className="mb-12">
        Integrujemy się z&nbsp;rozwiązaniami e-commerce:
      </h2>
      <Grid>
        <GridCol>
          <img src={Logo1} alt="Baselinker" />
        </GridCol>
        <GridCol>
          <img src={Logo2} alt="Baselinker" />
        </GridCol>
        <GridCol>
          <img src={Logo3} alt="Baselinker" />
        </GridCol>
        <GridCol>
          <img src={Logo4} alt="Baselinker" />
        </GridCol>
        <GridCol>
          <img src={Logo5} alt="Baselinker" />
        </GridCol>
        <GridCol>
          <img src={Logo6} alt="Baselinker" />
        </GridCol>
        <GridCol>
          <img src={Logo7} alt="Baselinker" />
        </GridCol>
        <GridCol>
          <img src={Logo8} alt="Baselinker" />
        </GridCol>
        <GridCol>
          <img src={Logo9} alt="Baselinker" />
        </GridCol>
        <GridCol>
          <img src={Logo10} alt="Baselinker" />
        </GridCol>
        <GridCol>
          <img src={Logo11} alt="Baselinker" />
        </GridCol>
        <GridCol>
          <img src={Logo12} alt="Baselinker" />
        </GridCol>
      </Grid>
    </SectionContainer>
  );
}
