import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

//Images
import Logo from "../../assets/images/symfonia_white.svg";

const FooterContainer = styled.footer`
  ${tw`
    bg-teal text-white mt-14 pt-7 pb-4
  `}

  img {
    ${tw`
      w-40
    `}
  }

  p {
    ${tw`
      text-xs mb-5
    `}
  }

  a {
    ${tw`
      text-white hover:underline
    `}
  }
`;

const ListContainer = styled.ul`
  ${tw`
    flex items-center text-xs
  `}

  li {
    &:not(:last-child) {
      &:after {
        content: "|";
        ${tw`
          inline-table mx-1
        `}
      }
    }
  }
`;

export function Footer() {
  return (
    <FooterContainer>
      <div className="container">
        <div className="flex flex-col items-center md:items-start">
          <a
            href="https://symfonia.pl/"
            target="_blank"
            rel="noopener noreferrer"
            className="mb-3 inline-block"
          >
            <img src={Logo} alt="symfonia" />
          </a>
          <p>&copy; Symfonia</p>
          <ListContainer>
            <li>
              <a
                href="http://symfonia.pl/regulaminy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Informacje prawne
              </a>
            </li>
            <li>
              <a
                href="https://erpdlafirm.pl/prywatnosc-i-cookies.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Prywatność i cookies
              </a>
            </li>
            <li>
              <a
                href="http://symfonia.pl/regulaminy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                RODO
              </a>
            </li>
          </ListContainer>
        </div>
      </div>
    </FooterContainer>
  );
}
