import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Button } from "../../components/button";
import { UList } from "../../components/ulist";
import { FluidType } from "../../components/fluidType";

// Image
import Image4 from "../../assets/images/image-4.jpg";

const SectionContainer = styled.section`
  ${tw`
    container pt-20
  `}

  h2 {
    ${tw`
      mb-12
    `}
  }

  img {
    ${tw`
     hidden md:block
    `}
  }
`;

const Grid = styled.div`
  ${tw`
    grid md:grid-cols-2 gap-4
  `}
`;

const GridCol = styled.div`
  ${tw`
    pr-5 md:pl-12
  `}

  h2 {
    ${tw`
      mb-4
    `}
  }

  p {
    ${tw`
      text-black
      `}
    ${FluidType(14, 18, "vw", "font-size", 480, 1200)}
  }
`;

export function Section6() {
  return (
    <SectionContainer>
      <div className="md:text-center">
        <h2>
          Wybierz Symfonię, aby&nbsp;
          <span className="text-green">maksymalizować sprzedaż</span> online
        </h2>
      </div>
      <Grid>
        <img src={Image4} alt="Symfonia" />
        <GridCol>
          <h3 className="mb-5">
            Czas pomyśleć o&nbsp;
            <span className="text-green">
              przejściu <br />
              do&nbsp;e-commerce
            </span>
            .
          </h3>
          <p className="mb-8">
            Oprogramowanie Symfonia wspiera biznes na&nbsp;każdym etapie
            sprzedaży dzięki&nbsp;integracji z&nbsp;popularnymi platformami
            e-commerce.
          </p>
          <UList
            list={[
              "Eksport danych do systemów sprzedażowych odbiorców",
              "Synchronizacja stanów magazynowych z kanałami sprzedaży",
              "Sprzedaż w różnych walutach",
              "Pobieranie zamówień ze sklepów internetowych i platform aukcyjnych",
              "Integracja z platformami e-commerce i sklepami internetowymi",
              "Rozwiązania typu multistore",
            ]}
          />
          <Button
            text={"Dowiedz się więcej"}
            url={"#sec-1__form"}
            anchor={true}
          />
        </GridCol>
      </Grid>
    </SectionContainer>
  );
}
