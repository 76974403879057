import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SCREENS } from "../../components/responsive";

// Images
import Logo from "../../assets/images/symfonia_white.svg";
import Image1 from "../../assets/images/sec-1-bg.jpg";

const SectionContainer = styled.section`
  ${tw`
    pt-9 relative z-10 overflow-hidden pb-6 md:pb-0
  `}
`;

const Container = styled.div`
  ${tw`
    container md:grid flex flex-col grid-cols-12 relative z-10 text-white
  `}
`;

const LogoContainer = styled.div`
  ${tw`
    mb-12
  `}
`;

const SectionContent = styled.div`
  ${tw`
    md:pt-10 md:pr-20 md:col-span-7
  `}
`;

const SectionBackground = styled.img`
  transform: translateX(-50%);
  ${tw`
    absolute left-1/2 -z-10 top-0 pointer-events-none w-full h-full object-cover
  `}

  @media (min-width: ${SCREENS.md}) {
    max-height: 720px;
  }
`;

const Frame = styled.iframe`
  min-height: 1200px;
  ${tw`
    m-0 p-0 w-full border-0 max-w-full shadow-xl bg-gray-100 rounded-xl
  `}

  @media (min-width: ${SCREENS.md}) {
    min-height: 1055px;
  }
`;

const IframeContainer = styled.div`
  min-height: 1100px;
  ${tw`
    md:col-span-5
  `}
`;

export function Section1() {
  return (
    <SectionContainer>
      <Container>
        <SectionContent>
          <LogoContainer>
            <img src={Logo} alt="symfonia" />
          </LogoContainer>
          <p className="mb-5">
            Nowoczesne oprogramowanie symfonia dla&nbsp;handlu hurtowego
          </p>
          <h1 className="mb-7">
            Dołącz do&nbsp;<span className="text-green">najlepszych </span>
            sprzedawców z&nbsp;programem Symfonia
          </h1>
          <p className="md:mb-14 mb-10">
            Wybierz kompleksowe, bezpieczne i&nbsp;efektywne wsparcie procesów
            sprzedażowych w&nbsp;Twojej firmie, by&nbsp;sprostać wyzwaniom
            przyszłości.
          </p>
        </SectionContent>
        <IframeContainer id="sec-1__form">
          <Frame
            title="form"
            src="https://symfonia.pl/Formularze/PROD/formLead/?id=HANDEL_LP_FORM_INDIRECT_THEYPL_GEO&url=[URL]"
          ></Frame>
        </IframeContainer>
      </Container>
      <SectionBackground src={Image1} alt="symfonia" />
    </SectionContainer>
  );
}
