import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Button } from "../../components/button";
import { UList } from "../../components/ulist";
import { FluidType } from "../../components/fluidType";

// Image
import Image1 from "../../assets/images/image-1.jpg";

const SectionContainer = styled.section`
  ${tw`
    container pt-20
  `}

  h2 {
    ${tw`
      mb-12
    `}
  }

  img {
    ${tw`
     hidden md:block
    `}
  }
`;

const Grid = styled.div`
  ${tw`
    grid md:grid-cols-2 gap-4
  `}
`;

const GridCol = styled.div`
  ${tw`
     md:px-10
  `}

  h2 {
    ${tw`
      mb-6
    `}
  }

  p {
    ${tw`
      text-black
    `}
    ${FluidType(14, 18, "vw", "font-size", 480, 1200)}
  }
`;

export function Section4() {
  return (
    <SectionContainer>
      <Grid>
        <GridCol>
          <h2 className="h3">
            Odkryj <span className="text-green">funkcjonalności programów</span>{" "}
            Symfonia Handel i&nbsp;Symfonia ERP Business Intelligence
          </h2>
          <p className="mb-8">
            Dzięki ergonomicznemu, prostemu w&nbsp;obsłudze oprogramowaniu
            zautomatyzujesz ofertowanie, zakupy, sprzedaż, magazynowanie
            i&nbsp;spedycję, a&nbsp;także z&nbsp;łatwością posłużysz się
            wyspecjalizowanymi narzędziami z&nbsp;zakresu zarządzania
            biznesowego.
          </p>
          <UList
            list={[
              "Tworzenie ofert, rejestracja zamówień, rezerwacje i wydanie towaru – realizowane w jednym procesie",
              "Baza informacji o stanie magazynu i wartości towarów",
              "Generowanie przelewów wychodzących i rozliczenia zapłat",
              "Monitoring informacji o należnościach i zobowiązaniach",
              "Wbudowane mechanizmy kontroli wewnętrznej",
              "Raporty i analizy",
            ]}
          />
          <Button text={"Sprawdź"} url={"#sec-1__form"} anchor={true} />
        </GridCol>
        <img src={Image1} alt="Symfonia" />
      </Grid>
    </SectionContainer>
  );
}
