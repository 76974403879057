import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

// Image
import Icon1 from "../../assets/images/trybiki.png";
import Icon2 from "../../assets/images/database.png";
import Icon3 from "../../assets/images/price.png";

const SectionContainer = styled.section`
  ${tw`
    container md:pt-8 pt-16 text-center
  `}

  h2 {
    ${tw`
      mb-12
    `}
  }
`;

const Grid = styled.div`
  ${tw`
    grid md:grid-cols-3 gap-4
  `}
`;

const GridCol = styled.div`
  ${tw`
    bg-white shadow-xl md:px-5 py-10 rounded-xl
  `}

  p {
    ${tw`
      text-black font-quicksand
    `}
  }
`;

const IconContainer = styled.div`
  ${tw`
    flex items-center justify-center mx-auto h-28 mb-7
  `}
`;

export function Section3() {
  return (
    <SectionContainer>
      <h2>Dlaczego warto wdrożyć oprogramowania Symfonia?</h2>
      <Grid>
        <GridCol>
          <IconContainer>
            <img src={Icon1} alt="1" />
          </IconContainer>
          <p>
            <span className="text-green">Zautomatyzujesz kontrolę</span>
            <br />
            nad&nbsp;stanami magazynowymi i&nbsp;procesem zakupowym
            od&nbsp;zamówienia do&nbsp;realizacji wysyłki
          </p>
        </GridCol>
        <GridCol>
          <IconContainer>
            <img src={Icon2} alt="1" />
          </IconContainer>
          <p>
            Otrzymasz{" "}
            <span className="text-green">wygodny i&nbsp;bezpieczny</span> dostęp
            do&nbsp;danych zawsze w&nbsp;czasie rzeczywistym
          </p>
        </GridCol>
        <GridCol>
          <IconContainer>
            <img src={Icon3} alt="1" />
          </IconContainer>
          <p>
            Będziesz mieć{" "}
            <span className="text-green">
              możliwość kształtowania polityki cenowej
            </span>{" "}
            i&nbsp;analizy rentowności towarów w&nbsp;czasie rzeczywistym
          </p>
        </GridCol>
      </Grid>
    </SectionContainer>
  );
}
