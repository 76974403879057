import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { FluidType } from "../fluidType";

interface UlistProps {
  list: string[];
}

const ListContainer = styled.ul`
  ${tw`
    m-0 mb-9
  `}
`;

const ListItem = styled.li`
  ${tw`
    relative pl-7 text-black block
  `}
  &:before {
    content: "";
    ${tw`
      block bg-green md:w-2 md:h-2 w-1 h-1 rounded-full absolute left-0 top-2 transform
    `}
  }
  ${FluidType(14, 18, "vw", "font-size", 480, 1200)}
`;

export function UList(props: UlistProps) {
  return (
    <ListContainer>
      {props.list.map((item: string, index: number) => (
        <ListItem key={index}>{item}</ListItem>
      ))}
    </ListContainer>
  );
}
