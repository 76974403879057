import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Button } from "../../components/button";
import { UList } from "../../components/ulist";
import { FluidType } from "../../components/fluidType";

// Image
import Image2 from "../../assets/images/image-2.jpg";
import Image3 from "../../assets/images/image-3.jpg";

const SectionContainer = styled.section`
  ${tw`
    container pt-20
  `}

  h2 {
    ${tw`
      mb-12
    `}
  }

  img {
    ${tw`
     hidden md:block
    `}
  }
`;

const Grid = styled.div`
  ${tw`
    grid md:grid-cols-2 gap-4
  `}
`;

const GridCol = styled.div`
  ${tw`
    md:pr-5
  `}

  h2 {
    ${tw`
      mb-4
    `}
  }

  p {
    ${tw`
      text-black
    `}
    ${FluidType(14, 18, "vw", "font-size", 480, 1200)}
  }
`;

export function Section5() {
  return (
    <SectionContainer>
      <Grid>
        <img src={Image2} alt="Symfonia" />
        <GridCol className="md:pl-12">
          <h2 className="h3">
            Korzystaj z&nbsp;cyfryzacji i&nbsp;
            <span className="text-green">
              automatyzacji procesów magazynowych i&nbsp;sprzedażowych
            </span>{" "}
            z&nbsp;programami Symfonia Mobilny Magazyn i&nbsp;Mobilny Handel
          </h2>
          <p className="mb-6">
            Poznaj narzędzia stosowane przez&nbsp;profesjonalistów
            zarządzających procesami handlowymi i&nbsp;zyskaj pełną kontrolę
            na&nbsp;urządzeniu mobilnym.
          </p>
          <h4 className="mb-5">Symfonia Mobilny Magazyn</h4>
          <UList
            list={[
              "Wsparcie aplikacji w przyjęciu, przesunięciu i wydaniu towaru",
              "Lokalizacja każdej partii towaru i każdej pojedynczej wysyłki",
              "Natychmiastowy dostęp do informacji o stanach magazynowych",
              "Dostęp z urządzeń z systemem Android",
              "Przygotowanie listy towarów do wysyłki",
              "Śledzenie towaru handlowego z dokładnością do sztuki w partii dostawy (traceability)",
              "Obsługa w języku polskim, angielskim, ukraińskim i białoruskim",
            ]}
          />
        </GridCol>
      </Grid>
      <Grid>
        <GridCol className="md:pt-16 md:px-5">
          <h4 className="mb-8">Symfonia Mobilny Magazyn</h4>
          <UList
            list={[
              "Kontrola stanów magazynowych, cen i rozliczeń z kontrahentami",
              "Przyjmowanie zamówień",
              "Wystawianie paragonów i faktur, również za usługę",
              "Planowanie i realizacja zgodnie z planem i terminarzem wizyty u klientów",
              "Zdjęcia przed i po wizycie handlowej",
              "Windykacja należności, kontrola limitów kupieckich",
            ]}
          />
          <Button
            text={"Dowiedz się więcej"}
            url={"#sec-1__form"}
            anchor={true}
          />
        </GridCol>
        <img src={Image3} alt="Symfonia" />
      </Grid>
    </SectionContainer>
  );
}
